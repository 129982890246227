<template>
  <div class="sort">
    <topNav title="分類" :noborder="true"></topNav>
    <div class="sortbox">
      <div class="choosebox">
        <a
          :class="{ boy: true, choose: chooseIndex == 0 }"
          href="javaScript:"
          @click="choosesort(0)"
          >男生</a
        >
        <a
          :class="{ girl: true, choose: chooseIndex == 1 }"
          href="javaScript:"
          @click="choosesort(1)"
          >女生</a
        >
        <span :class="{ lines: true, isgirl: chooseIndex == 1 }"></span>
      </div>
    </div>
    <div class="sortwrap" v-show="chooseIndex == 0&&boyList&&boyList.length>0">
      <ul class="clearfix">
        <li v-for="item in boyList" :key="item.sortId" class="fl">
          <a
            class="clearfix"
            href="javaScript:"
            @click="goSeeSort(item.name, item.sortId)"
          >
            <div class="left fl">
              <img :src="item.iconUrlSmall[0]" class="bottom" alt="" />
              <img :src="item.iconUrlSmall[1]" class="top" alt="" />
            </div>
            <div class="right">
              <p class="sortname">{{ item.name }}</p>
              <div class="num">{{ item.value }}冊</div>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <div class="sortwrap" v-show="chooseIndex == 1&&girlList&&girlList.length>0">
      <ul class="clearfix">
        <li v-for="item in girlList" :key="item.sortId" class="fl">
          <a
            class="clearfix"
            href="javaScript:"
            @click="goSeeSort(item.name, item.sortId)"
          >
            <div class="left fl">
              <img :src="item.iconUrlSmall[0]" class="bottom" alt="" />
              <img :src="item.iconUrlSmall[1]" class="top" alt="" />
            </div>
            <div class="right">
              <p class="sortname">{{ item.name }}</p>
              <div class="num">{{ item.value }}冊</div>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <nodata class="posCen" v-if="chooseIndex == 0&&boyList&&boyList.length==0||chooseIndex == 1&&girlList&&girlList.length==0">
      <div slot="datatitle" class="nodata-tip">暫無數據！</div>
    </nodata>
  </div>
</template>

<script>
import { sortList } from "apiurl/search";
export default {
  data() {
    return {
      chooseIndex: 0,
      boyList: null,
      girlList: null
    };
  },
  created() {
    this.$nextTick(() => {
      this.getNaSort();
      this.getNvSort();
    });
  },
  methods: {
    choosesort(index) {
      this.chooseIndex = index;
    },
    getNaSort() {
      sortList({ group: 1 }).then(res => {
        if (res.success) {
          this.boyList = res.data;
        }
      });
    },
    getNvSort() {
      sortList({ group: 2 }).then(res => {
        if (res.success) {
          this.girlList = res.data;
        }
      });
    },
    goSeeSort(name, id) {
      this.$router.push({ path: `/sort/${id}`, query: { name } });
    }
  }
};
</script>

<style lang="stylus" scoped>
.sortbox
    position fixed
    box-shadow:0px 1px 16px 0px rgba(47,50,127,0.11);
    background #fff
    z-index 10
    top 100px
    left 0
    height 64px
    text-align center
    width 100%
    .choosebox
        display inline-block
        position relative
        height 64px
        .lines
            position absolute
            bottom 0
            left 0
            left 50px
            display inline-block
            transition all 0.17s ease-in-out
            width:20px;
            height:4px;
            background:linear-gradient(90deg,rgba(126,223,255,1) 0%,rgba(73,202,255,1) 100%);
            box-shadow:0px 1px 3px 0px rgba(108,216,255,1);
            border-radius:3px;
            &.isgirl
                left 160px
        a
            color #999999
            font-size 30px
            transition all 0.17s ease-in-out
            height 64px
            &.boy
                margin 0 26px
            &.girl
                margin 0 26px
            &.choose
                color #2188F2
                font-size 34px
.sortwrap
    display block
    margin-top 194px
    ul
        padding 0 24px
        li
            width:338px;
            height:189px;
            background:rgba(255,255,255,1);
            box-shadow:0px 1px 8px 0px rgba(146,146,146,0.24);
            border-radius:13px;
            padding 26px
            box-sizing border-box
            margin-bottom 26px
            &:nth-child(2n)
                margin-left 25px
            .left
                width 152px
                height 138px
                position relative
                margin-right 19px
                .bottom
                    position absolute
                    bottom 0
                    right 0
                    z-index 1
                    width:83px;
                    height:111px;
                    background:rgba(216,216,216,1);
                    box-shadow:0px 2px 4px 0px rgba(196,196,196,0.5);
                    border-radius:6px;
                .top
                    width:98px;
                    height:138px;
                    background:rgba(216,216,216,1);
                    box-shadow:0px 2px 4px 0px rgba(196,196,196,0.5);
                    border-radius:6px;
                    position absolute
                    z-index 2
            .right
                margin-top 35px
                .sortname
                    color #333333
                    font-size 28px
                    margin-bottom 2px
                    line-height 40px
                .num
                    font-size 22px
                    color #999
</style>
