import requset from "utils/request";
import baseURL from "./env";
export function sortList(params) {
  return requset({
    url: `${baseURL.searchApi}/search/sort`,
    method: "GET",
    params
  });
}
export function sortAll(params) {
  return requset({
    url: `${baseURL.searchApi}/search/all`,
    method: "GET",
    params
  });
}
export function searchAll(params) {
  return requset({
    url: `${baseURL.searchApi}/search/query`,
    method: "GET",
    params
  });
}
export function conditions(params) {
  return requset({
    url: `${baseURL.searchApi}/search/conditions`,
    method: "GET",
    params
  });
}
